/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

   "use strict";

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        //Accordion multi open
		$('.multiaccordion__content').hide();

		// Main navigation slidedown submenu
        $('.multiaccordion__head').on('click', function(event){
            $(this).next('.multiaccordion__content').slideToggle('normal');


				$(this).find('.content-block-header--accordian__arrow').toggleClass("active");


        });


        // Sticky header
        $(window).scroll(function() {
        if ($(this).scrollTop() > 1){
            $('.nav-bar').addClass("sticky");
          }
          else{
            $('.nav-bar').removeClass("sticky");
          }
        });

		// Animated buger icon that activates page slide
        //open the lateral panel
        $('.menu-button-trigger').on('click', function(event){
            event.preventDefault();
            $('.cd-panel').toggleClass('is-visible');
            $('.menu-button-trigger').toggleClass( "is-open" );
        });
        //clode the lateral panel
        $('.cd-panel').on('click', function(event){
            if( $(event.target).is('.cd-panel') || $(event.target).is('.cd-panel-close') ) {
                $('.cd-panel').removeClass('is-visible');
                $('.menu-button-trigger').toggleClass( "is-open" );
                event.preventDefault();
            }
        });

        // Main navigation slidedown submenu
        $('ul li .panel-main-menu__arrow').on('click', function(event){
            $(this).next('ul.sub-menu').slideToggle('normal');
            $(this).toggleClass("active");
        });

        // Slider or banner - remove arrows if only one slide
        $('.cycle-slideshow').each(function( i ) { if( $(this).children('.cycle-slide').not('.cycle-sentinel').length <= 1 ) $(this).addClass('cycle-single'); });

        //Accordion multi open
        var rm = $(".js-slidedown-box__trigger"),
            moreText = "Read More",
            lessText = "Read Less";

        rm.click(function () {
            var $this = $(this);
            $this.prev().slideToggle();
            $this.text($this.text() == lessText ? moreText : lessText);
        });

        // Gallery of slides for transport block
        $('.gallery').each(function(key, item) {
            var sliderIdName = 'gallery' + key;
            var sliderNavIdName = 'gallery-nav-thumbnails' + key;

            this.id = sliderIdName;
            $('.gallery-nav-thumbnails')[key].id = sliderNavIdName;

            var sliderId = '#' + sliderIdName;
            var sliderNavId = '#' + sliderNavIdName;

            $(sliderId).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: false,
                asNavFor: sliderNavId
            });

            $(sliderNavId).slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: sliderId,
                dots: true,
                arrows: true,
                centerMode: false,
                focusOnSelect: true
            });
        });

        // Walks related walks sliderId// News events home slider


        $('.related-walks-slider').slick({
            autoplay: true,
            autoplaySpeed: 3000,
            infinite: true,
            slidesToShow: 4,
            arrows: true,
            centerMode: false,

            responsive: [
                {
                    breakpoint: 980, // tablet breakpoint
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
            breakpoint: 600, // mobile breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            ]
        });


        $('.map-location-gallery').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
            centerMode: false,
            focusOnSelect: true,
            autoplay: true,
            infinite: false,

            responsive: [
                {
                    breakpoint: 980, // tablet breakpoint
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
            breakpoint: 600, // mobile breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            ]



        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
